.bgRegulationsPattern {
  background-image: url("../../assets/patternRegulations.svg");
}

@media screen and (min-width: 100px) and (max-width: 400px){
  .imageResponsive {
    margin-top: 45%;
  }
}

@media screen and (min-width: 400px)  and (max-width: 500px){
  .imageResponsive {
    margin-top: 52%;
  }
}

@media screen and (min-width: 500px) and (max-width: 768px) {
  .imageResponsive {
    margin-top: 220px;
  }
}


@media screen and (min-width: 1024px) and (max-width: 1080px) {
  .riskImageContainer {
    height: 630px;
  }
}

@media screen and (min-width: 1080px) and (max-width: 1107px) {
  .riskImageContainer {
    height: 610px;
  }
}

@media screen and (min-width: 1107px) and (max-width: 1247px) {
  .riskImageContainer {
    height: 570px;
  }
}

@media screen and (min-width: 1247px) and (max-width: 1287px) {
  .riskImageContainer {
    height: 535px;
  }
}

@media screen and (min-width: 1287px) and (max-width: 1624px){
  .riskImageContainer {
    height: 510px;
  }
}

@media screen and (min-width: 1624px){
  .riskImageContainer {
    height: 535px;
  }
}